@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Outfit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9ecf1;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Disable native date picker dropdown only on mobile Safari */
@media screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio:0) {
  .date-input::-webkit-calendar-picker-indicator {
    display: none;
  }
}